import { ApplicationUser } from '../../Shared/Models/ApplicationUser';
import { ChatGroup } from '../../Shared/Models/ChatGroup';
import ClientTrackLogo from '../../Shared/images/client-track-logo.svg';
import { FunctionComponent, useEffect, useState } from 'react';
import { Link } from 'react-router-dom';

interface ChatNavContactProps {
    contact: ChatGroup,
    user: ApplicationUser | undefined
}

const ChatNavContact: FunctionComponent<ChatNavContactProps> = ({ contact, user }) => {
    const contactName = contact.users.filter(u => u.id !== user?.entity.id).map(filtered => filtered.name).join(" & ");
    const groupStatus = contact.users.find(u => u.id === user?.entity.id)?.connectionStatus;

    const [unreadCount, setUnreadCount] = useState<number>(0);

    useEffect(() => {
        const chatGroupUser = contact.users.find(x => x.id === user?.entity.id);
        if (chatGroupUser) {
            let unreadMessages = contact.messages.filter(x => !chatGroupUser.lastRead || x.time > chatGroupUser?.lastRead);
            setUnreadCount(unreadMessages.length);
        }
    }, [contact])

    return (
        <li className={`chat-list-title ${groupStatus === -1 ? "bg-warning" : ""}`} 
            data-toggle-extra="tab" data-target-extra="#user-content-1" data-testid="contact">
            <div className="media  justify-content-between chat-user-box rounded align-items-center">
                <div>
                    <div className="chat-profile mr-3">
                        <img src={ClientTrackLogo} alt="chat-user" className="avatar-50 " />
                        <span className="chat-status chat-status-">
                            <i className="ri-checkbox-blank-circle-fill"></i>
                        </span>
                    </div>
                </div>
                <Link to={`/main/chat/${contact.id}`} className={`media-body chat-text`}>
                    <div className="d-flex align-items-center chat-right">
                        <h6 data-testid="name">{contactName}</h6>
                    </div>
                </Link>
                {
                    unreadCount > 0 &&
                    <span className="badge badge-primary">{unreadCount}</span>
                }
            </div>
        </li> 
    )
}

export default ChatNavContact;