import React from 'react';
import './App.css';
import 'react-toastify/dist/ReactToastify.css';
import 'react-phone-number-input/style.css'
import './bootstrap.css'
import { Route, RouterProvider, createBrowserRouter, createRoutesFromElements } from 'react-router-dom';

import AuthRoute from './Shared/Nav/AuthRoute';
import ChatPage from './Chat/ChatPage';
import ChatWindow from './Chat/Components/ChatWindow';
import ContactPage from './User/ContactPage';
import Login from './Authentication/Components/Login';
import Main from './Shared/Nav/Main';
import { ToastContainer } from 'react-toastify';
import UserProfile from './User/UserProfile'
import ViewInvitation from './User/ViewInvitation';
import ViewRegistration from './Authentication/Components/ViewRegistration';
import CompleteRegistration from './Authentication/Components/CompleteRegistration';
import UserContextRoute from './Shared/UserContextRoute';

const router = createBrowserRouter(createRoutesFromElements(
  <>
    <Route path="/register" element={<ViewRegistration />} />
    <Route element={<UserContextRoute />}>
      <Route path="/" element={<Login />} />
      <Route path="/login" element={<Login />} />
      <Route path="/linkaccount" element={<CompleteRegistration />} />
      <Route element={<AuthRoute />}>
        {/* <Route path="/feedback" element={<FeedbackPage />} /> */}
        <Route path="/main" element={<Main />} >
          <Route path="user" element={<Main />} >
            <Route path="profile" element={<UserProfile />} />
            <Route path="viewinvitation" element={<ViewInvitation />} />
            <Route path="contact" element={<ContactPage />} />
          </Route>
          <Route path="chat" element={<ChatPage />}>
            <Route path=":groupid" element={<ChatWindow />} />
          </Route>
        </Route>
      </Route>
    </Route>
  </>
), { basename: process.env.PUBLIC_URL })

function App() {
  return (
    <>
      <RouterProvider router={router}></RouterProvider>
      <ToastContainer />
    </>
  );
}

export default App;
