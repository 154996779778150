import { Configuration, LogLevel } from "@azure/msal-browser";
import { LoginRequest } from "./Models/LoginRequest";
import { ApiConfig } from "./Models/ApiConfig";
import {B2cPolicies} from "./Models/B2cPolicies";

// Browser check variables
// If you support IE, our recommendation is that you sign-in using Redirect APIs
// If you as a developer are testing using Edge InPrivate mode, please add "isEdge" to the if check
const ua = window.navigator.userAgent;
const msie = ua.indexOf("MSIE ");
const msie11 = ua.indexOf("Trident/");
const msedge = ua.indexOf("Edge/");
const firefox = ua.indexOf("Firefox");
const isIE = msie > 0 || msie11 > 0;
const isEdge = msedge > 0;
const isFirefox = firefox > 0; // Only needed if you need to support the redirect flow in Firefox incognito

/**
 * Enter here the user flows and custom policies for your B2C application
 * To learn more about user flows, visit: https://docs.microsoft.com/en-us/azure/active-directory-b2c/user-flow-overview
 * To learn more about custom policies, visit: https://docs.microsoft.com/en-us/azure/active-directory-b2c/custom-policy-overview
 */
 export const b2cPolicies: B2cPolicies = {
    authorities: {
        signUpSignIn: {
            authority: process.env.REACT_APP_B2C_SIGN_IN_AUTHORITY ?? 'https://ClientExchangeDev.b2clogin.com/ClientExchangeDev.onmicrosoft.com/B2C_1_clientempower_signin'
        },
        signUp: {
            authority: process.env.REACT_APP_B2C_REGISTRATION_AUTHORITY ?? 'https://ClientExchangeDev.b2clogin.com/ClientExchangeDev.onmicrosoft.com/B2C_1_ClientEmpower_Signup'
        }
    },
    authorityDomain: process.env.REACT_APP_B2C_AUTHORITY ?? 'ClientExchangeDev.b2clogin.com'
}

// Config object to be passed to Msal on creation
export const msalConfig: Configuration = {
    auth: {
        clientId: process.env.REACT_APP_B2C_CLIENTID || '',
        authority: b2cPolicies.authorities.signUpSignIn.authority,
        knownAuthorities: [b2cPolicies.authorityDomain],
        redirectUri: process.env.REACT_APP_B2C_REDIRECT,
        postLogoutRedirectUri: "/"
    },
    cache: {
        cacheLocation: "localStorage",
        storeAuthStateInCookie: isIE || isEdge || isFirefox
    },
    system: {
        allowNativeBroker: false, // Disables WAM Broker
        loggerOptions: {
            loggerCallback: (level: any, message: any, containsPii: any) => {
                if (containsPii) {
                    return;
                }
                switch (level) {
                    case LogLevel.Error:
                        console.error(message);
                        return;
                    case LogLevel.Info:
                        console.info(message);
                        return;
                    case LogLevel.Verbose:
                        console.debug(message);
                        return;
                    case LogLevel.Warning:
                        console.warn(message);
                        return;
                    default:
                        return;
                }
            }
        }
    }
};

const scopes: string[] = [
    process.env.REACT_APP_B2C_ENTITY_READ_SCOPE ?? 'https://ClientExchangeDev.onmicrosoft.com/ce-api/Entity.Read',
    process.env.REACT_APP_B2C_ENTITY_WRITE_SCOPE ?? 'https://ClientExchangeDev.onmicrosoft.com/ce-api/Entity.Write',
    process.env.REACT_APP_B2C_CHAT_READ_SCOPE ?? 'https://ClientExchangeDev.onmicrosoft.com/ce-api/Chat.Read',
    process.env.REACT_APP_B2C_CHAT_WRITE_SCOPE ?? 'https://ClientExchangeDev.onmicrosoft.com/ce-api/Chat.Write',
    process.env.REACT_APP_B2C_USER_PROFILE_SCOPE ?? 'https://ClientExchangeDev.onmicrosoft.com/ce-api/userprofile',
]

// Scopes you add here will be prompted for consent during login
export const loginRequest: LoginRequest = {
    scopes: ["openid", 
        ...scopes
    ],
    redirectUri: process.env.REACT_APP_DOMAIN ?? 'https://clientempower-dev.eccovia.com/'
};

/**
 * Enter here the coordinates of your web API and scopes for access token request
 * The current application coordinates were pre-registered in a B2C tenant.
 */
export const apiConfig: ApiConfig = {
    scopes: scopes,
    uri: process.env.REACT_APP_B2C_URI ?? 'https://ClientExchangeDev.onmicrosoft.com/ce-api'
};