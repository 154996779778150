import { ChatMessageType, UIChatMessage } from "../../Shared/Models/ChatTypes";
import { useEffect, useState } from "react";

import ClientTrackLogo from '../../Shared/images/client-track-logo.svg';
import Skippy from '../../Shared/images/Skippy.jpg';
import Loading, { LoadingSize } from "../../Shared/Components/Loading";

const ChatMessage = (props: UIChatMessage) => {
    const { messageType, messageContent, messageDate, isSending, showSenderName, chatGroup, sender } = props;
    const [alignClass, setAlignClass] = useState<string>('');
    const [bgColor, setBgColor] = useState<string>('');
    const [messageTypeClass, setMessageTypeClass] = useState<string>('');
    const [senderName, setSenderName] = useState<string>('');

    useEffect(() => {
        const allUsers = chatGroup?.users.concat(chatGroup?.watch);
        const senderName = allUsers?.find( x => x.id === sender)?.name || '';
        
        setSenderName(senderName);
    }, [chatGroup, sender])

    useEffect(() => {
        switch (messageType) {
            case ChatMessageType.OtherUser:
                setAlignClass('chat-left');
                setBgColor('bg-light');
                setMessageTypeClass('current-user');
                break;
            case ChatMessageType.User:
                setAlignClass('chat-right');
                setBgColor('bg-primary-light')
                setMessageTypeClass('other-user');
                break;
            default:
                break;
        }
    }, [messageType])

    return messageType !== ChatMessageType.System 
        ? 
            (
                <>
                <div className="d-flex flex-column">
                    {
                        showSenderName &&
                        <span className={`${messageTypeClass} senderName`}>{senderName}</span>
                    }
                </div>
                <div className={`media my-2 justify-content-between ${messageTypeClass}`} data-testid="message-container">
                            <div className="chat-profile">
                                <img src={messageType === ChatMessageType.OtherUser ? ClientTrackLogo : Skippy} alt="chat-user" className="avatar-40 " />
                            </div>
                        <div className="media-body chat-text">
                            <div className={`${alignClass} d-flex align-items-center`}>
                                <div className={`chating d-flex align-items-center ${bgColor}` }>
                                    <p className="mr-2 mb-0 prewrap">{messageContent}</p>
                                    {isSending ?
                                        <Loading Size={LoadingSize.Small} /> :
                                        <p className="mb-0"><small>{new Date(messageDate).toLocaleTimeString()}</small></p>
                                    }
                                </div>      
                            </div>
                        </div>
                    </div>
                </>
            )
        : 
            (
                <div className="d-flex justify-content-center">
                    <div className="d-flex justify-content-center align-items-center media my-2 bg-message-blue pl-4 pr-4 pt-2 pb-2 border-radius-normal" data-testid="message-container">
                        <div className="d-flex flex-column">
                            <div><strong>{senderName} - Broadcast</strong></div>
                            <div className="chat-text">{messageContent}</div>
                        </div>
                        <div className="d-flex align-self-end">
                            <small>{new Date(messageDate).toLocaleTimeString()}</small>
                        </div>
                    </div>
                </div>
            )
}

export default ChatMessage;