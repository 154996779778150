import { b2cPolicies, loginRequest } from "../AuthConfig";
import { RedirectRequest } from "@azure/msal-browser";
import { useMsal } from "@azure/msal-react";
import { useState } from "react";

export const SignInButton = () => {
    const { instance } = useMsal();  
    const signUpRequest: RedirectRequest = {
       authority: b2cPolicies.authorities.signUpSignIn.authority,
       scopes: loginRequest.scopes,
       redirectUri: process.env.REACT_APP_DOMAIN
    }

    const [anchorEl, setAnchorEl] = useState<HTMLButtonElement | null>(null);
    const open = Boolean(anchorEl);

    const handleLogin = () => {
        setAnchorEl(null);
        instance.loginRedirect(signUpRequest);
    }

    return (
        <button className="btn btn-primary w-100" onClick={handleLogin} >Sign In</button>
    )
};