import '../backend.css';

import { Outlet, useOutletContext, useParams } from 'react-router-dom';
import { useContext, useEffect, useState } from 'react';

import { ChatGroup, ChatGroupUser } from '../Shared/Models/ChatGroup';
import { ChatUser } from '../Shared/Models/ChatUser';
import { UserProfile } from '../Shared/Models/UserProfile';
import { UserProfileContext } from '../Hooks/useProfile';
import jwt from 'jwt-decode';
import { AccountInfo } from '@azure/msal-browser';
import { useMsal } from '@azure/msal-react';
import { Session } from '../Shared/Models/Session';
import ApiHelper from '../Shared/ApiHelper';
import { APIChatMessage } from '../Shared/Models/ChatTypes';
import { HubConnection, HubConnectionBuilder, IHttpConnectionOptions } from '@microsoft/signalr';

type ContextType = { 
    ChatUser: ChatUser | undefined,
    Group: ChatGroup | undefined,
    IsReconnecting: boolean,
    Messages: APIChatMessage[],
    SetMessages: React.Dispatch<React.SetStateAction<APIChatMessage[]>>,
    UpdateGroup:  React.Dispatch<React.SetStateAction<ChatGroup>>,
    Connection: HubConnection | undefined
};

export default function ChatPage() {
    const [chatUser, setChatUser] = useState<ChatUser>();
    const [connection, setConnection] = useState<HubConnection>();
    const userProfile = useContext<UserProfile>(UserProfileContext);
    const [chatWindowGroup, setChatWindowGroup] = useState<ChatGroup>({} as ChatGroup);
    const { groupid } = useParams();
    const { accounts, instance } = useMsal();
    const [ session, setSession ] = useState<Session>();
    const [ hubToken, setHubToken ] = useState<string | undefined>();
    const [ api ] = useState(new ApiHelper());
    const [ isReconnecting ] = useState<boolean>(false);
    const [chatWindowGroupMessages, setChatWindowGroupMessages] = useState<APIChatMessage[]>([]);
    const [ receivedMessage, setReceivedMessage ] = useState<APIChatMessage>();
    const [ newGroups, setNewGroups] = useState<ChatGroup[]>([]);
    const [ timeoutId, setTimeoutId ] = useState<NodeJS.Timeout>();

    useEffect(() =>{
        const acct = accounts?.find((a: AccountInfo) => a.idTokenClaims?.aud === process.env.REACT_APP_B2C_CLIENTID);
        if (acct && !session) {
            const postobj = JSON.stringify({ email: acct.username });
            api.callApi(
                instance,
                [process.env.REACT_APP_B2C_SCOPE ?? ''],
                `${process.env.REACT_APP_CLIENTEX_APIBASE}/authentication/session`,
                "POST",
                postobj
            ).then(res => res.json())
            .then((data: Session) =>  {
                setSession(data);
            });
        }
    }, [api, instance, accounts, session]);

    useEffect(() => {
        if (session?.id) {
            const endpoint = `${process.env.REACT_APP_SIGNALR_HUB}/auth/session/${session.id}`;
            fetch(endpoint, { method: "POST", body: JSON.stringify(session), headers: { 'Content-Type': 'application/json' }})
            .then(res => res.json())
            .then((data: {id: string, token: string}) => {
                setHubToken(data.token);
            });
        }
    }, [session]);

    useEffect(() => {
        setChatUser(
            hubToken
                ? jwt<ChatUser>(hubToken)
                : undefined
        );
    }, [session, hubToken])

    useEffect(() => {
        if (groupid && chatUser) {
            const tid = setTimeout(() => MarkChatMessagesRead(), 3000);

            if (timeoutId) {
                clearTimeout(timeoutId);
            }
            setTimeoutId(tid);
        }

        return () => {
            if (timeoutId) {
                clearTimeout(timeoutId);
                setTimeoutId(undefined);
            }
        }
    }, [groupid, receivedMessage, chatUser]);

    useEffect(() => {
        const previousGroups = userProfile?.ChatGroups === undefined ? [] : userProfile?.ChatGroups.filter(old => !newGroups.find(group => group.id == old.id));
        userProfile?.SetChatGroups([...previousGroups, ...newGroups])
    }, [newGroups])

    useEffect(() => {
        const hubOptions: IHttpConnectionOptions = { 
            accessTokenFactory: function(): string { return hubToken || ''  }
        };
        const hubConnection: HubConnection = new HubConnectionBuilder()
        .withUrl(process.env.REACT_APP_SIGNALR_HUB || '', hubOptions)
        .withAutomaticReconnect()
        .build();

        setConnection(hubConnection);
    }, [hubToken, session]);

    useEffect((): () => void => {
        if (connection && session && connection?.state === 'Disconnected' && hubToken) {
            connection.on('groupUpdate', function(groups: ChatGroup[]) {
                //Handles the user joining a group
                setNewGroups(groups);
            });
            
            connection.start()
                .then((data) => {
                    connection.on("message", (msg) => setReceivedMessage(msg))
                })
                .catch((err) => {
                    console.error(err);
                })
        }
        return () => connection?.stop();
    }, [connection, hubToken, session])

    useEffect(() => {
        if (userProfile?.ChatGroups && receivedMessage) {
            const updatedGroups = userProfile?.ChatGroups.map((grp: ChatGroup) => grp.id === receivedMessage.group 
                ? { ...grp, messages: [...grp.messages, receivedMessage]} 
                : grp);

            userProfile.SetChatGroups([...updatedGroups]);
        }

    }, [connection?.state, receivedMessage])

    useEffect(() => {
        setChatWindowGroupMessages([...chatWindowGroupMessages, receivedMessage as APIChatMessage])
    }, [connection?.state, receivedMessage])

    const MarkChatMessagesRead = async () => {
        const patchObj = [{
            op: "replace", 
            path: "/lastRead",
            value: new Date().toISOString()
        }]

        if (groupid && (!receivedMessage || receivedMessage.group === groupid) && chatUser) {
            api.callApi(
                instance,
                [process.env.REACT_APP_B2C_SCOPE ?? ''],
                `${process.env.REACT_APP_CLIENTEX_APIBASE}/chatgroup/${groupid}/user/${chatUser.id}`,
                "PATCH",
                JSON.stringify(patchObj),
                new Headers([["Content-Type", "application/json-patch+json"]])
            ).then(res => res.json())
            .then((data: ChatGroup) =>  {
                if (userProfile?.ChatGroups && groupid) {
                    const updatedGroups = userProfile?.ChatGroups.map((grp: ChatGroup) => { 
                        if (grp.id === groupid) {
                            const updatedUser = grp.users.find(x => x.id === chatUser.id);
                                if (updatedUser) {
                                    updatedUser.lastRead = patchObj[0].value;
                                    return { ...grp, users: [ ...grp.users ] }
                                }
                        }
                        return grp;
                    })
                    userProfile.SetChatGroups([...updatedGroups]);
                }
            });
        }
    }

    const outletContext: ContextType = { 
        Connection: connection, 
        ChatUser: chatUser,
        Group: chatWindowGroup,
        UpdateGroup: setChatWindowGroup, 
        Messages: chatWindowGroupMessages, 
        SetMessages: setChatWindowGroupMessages,
        IsReconnecting: isReconnecting
    };

    return (
        <>
            { groupid !== undefined
                ? 
                <Outlet context={outletContext} />
                :
                <div className="content-page-chat default-box d-flex justify-center align-items-center">
                    <div className="container-fluid p-0 d-flex flex-column align-items-center justify-center">
                        <p className="text-center">You are not viewing a conversation.</p>
                        <button className="btn btn-primary default-chat-btn" onClick={e => userProfile?.SetChatNavOpen(true)}>View a conversation</button>
                    </div>
                </div>
            }

        </>
        );
};

export function useChatPageContext() {
    return useOutletContext<ContextType>();
  }