import '../../backend.css';
import React, { FunctionComponent, useContext, useEffect } from 'react';

import { ChatGroup } from '../../Shared/Models/ChatGroup';
import ChatNavContact from './ChatNavContact';
import Skippy from '../../Shared/images/Skippy.jpg';
import { UserProfile } from '../../Shared/Models/UserProfile';
import { UserProfileContext } from '../../Hooks/useProfile';

interface ChatNavProps {
}
const ChatNav: FunctionComponent<ChatNavProps> = () => {
    const userProfile = useContext<UserProfile>(UserProfileContext);

    return (
        <div className={`chat-data-left animate__animated p-0`}>
                <div className="chat-search px-3">
                    <div className="d-flex align-items-center pt-3">
                        <div className="chat-profile mr-3">
                            <img src={Skippy} className="img-fluid avatar-50 rounded" alt="profile" data-target="#profile-modal" data-toggle="modal" />
                            <div className="chat-status">
                                <div className="dropdown border-none">
                                <i className="ri-checkbox-blank-circle-fill text-primary"></i>
                                </div>
                            </div>
                        </div>
                        <div className="media-support">
                            <h5>{userProfile?.AppUser?.entity.name}</h5>
                            <p className="mb-0 font-size-14">{userProfile?.Account?.username}</p>
                        </div>
                        <button type="submit" className="close-btn-res" onClick={e => userProfile?.SetChatNavOpen(false)}>
                            <svg width="20" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                                <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M15 19l-7-7 7-7"></path>
                            </svg>
                        </button>
                    </div>
                </div>
                <div className="chat-sidebar-channel">
                <div className="tab-pane fade active show" id="pills-contact" role="tabpanel" aria-labelledby="pills-contact-tab">
                     <div className="d-flex align-items-center mb-3 justify-content-between px-3 mt-4">
                        <div className="">
                            <p className="mb-0"> My Contacts </p>
                        </div>
                     </div>
                     <div className="recent-chat mt-3">
                        <ul className="chat-list scroller list-unstyled m-0 px-2">
                            { userProfile?.ChatGroups === undefined ?
                                (
                                    <p className="text-center font-bold text-danger">
                                        Chat is temporarily down.
                                        <br />
                                        Please try again later.
                                    </p>
                                )
                                :
                                (
                                    userProfile?.ChatGroups.length === 0 ?
                                    (<p className="text-center font-bold">No active conversations.</p>)
                                    : 
                                    (
                                        userProfile?.ChatGroups.map((grp: ChatGroup) => (
                                            <ChatNavContact contact={grp} user={userProfile?.AppUser} key={grp.id} />
                                        ))
                                    )
                                )
                            }
                        </ul>
                     </div>
                  </div>
            </div>
        </div>
    )
};

export default ChatNav;