import { APIChatMessage, ChatMessageType } from '../../Shared/Models/ChatTypes';
import { FunctionComponent, useContext, useEffect, useRef, useState } from 'react';

import ApiHelper from '../../Shared/ApiHelper';
import ChatMessage from './ChatMessage';
import ClientTrackLogo from '../../Shared/images/client-track-logo.svg';
import InfiniteScroll from 'react-infinite-scroll-component';
import Loading from '../../Shared/Components/Loading';
import { UserProfile } from '../../Shared/Models/UserProfile';
import { UserProfileContext } from '../../Hooks/useProfile';
import { useChatPageContext } from '../ChatPage';
import { useMsal } from '@azure/msal-react';
import { useNavigate, useParams } from 'react-router-dom';
import ChatGroupAcceptance from './ChatGroupAcceptance';
import { ChatConnectionStatus } from '../../Shared/Models/ChatConnectionStatus';
import { StatusCode } from '../../Shared/Models/StatusCode';
import { HubConnectionState } from '@microsoft/signalr';
import { toast } from 'react-toastify';
import { ToastConfig } from '../../Shared/Models/ToastConfig';
import { ChatGroup, ChatGroupUser } from '../../Shared/Models/ChatGroup';
import { StatusMessage } from '../../Shared/Models/StatusMessage';

const ChatWindow: FunctionComponent = () => {
    const { Connection, ChatUser, Messages, SetMessages } = useChatPageContext();
    const [ messageGroups, setMessageGroups ] = useState<{[key: string]: APIChatMessage[]}>();
    const [ messageInput, setMessageInput ] = useState<string>('');
    const [ chatGroupName, setChatGroupName] = useState<string>('');
    const { groupid } = useParams();
    const chatbox = useRef<HTMLDivElement>(null);
    const userProfile = useContext<UserProfile>(UserProfileContext);
    const [api] = useState(new ApiHelper());
    const { instance } = useMsal();
    const [hasMoreMessages, setHasMoreMessages] = useState<boolean>(true);
    const [chatGroupUser, setChatGroupUser] = useState<ChatGroupUser | undefined>();
    const navigate = useNavigate();
    const MESSAGE_PAGING_LENGTH: number = 29;
    const [ isReconnecting, setIsReconnecting ] = useState<boolean>(false);
    const [ Group, SetGroup] = useState<ChatGroup>();
    
    const sort = (messages: APIChatMessage[] | undefined) => {
      var x = messages?.sort((msg1: APIChatMessage, msg2: APIChatMessage) => {
         const t1 = new Date(msg1.time);
         const t2 = new Date(msg2.time);
         return t1 < t2 ? -1 : t1 > t2 ? 1 : 0;
      }) ?? [];
      return x;
   }

   useEffect(() => {
      if (groupid && userProfile?.ChatGroups) {
         const group = userProfile?.ChatGroups.find(x => x.id === groupid);
         SetGroup(group);
      } 
   }, [groupid, userProfile?.ChatGroups])

   const getEarliestMessage = (messages: APIChatMessage[] | undefined) => sort(messages).at(0);

   useEffect(() => {
      if (chatbox.current) {
          chatbox.current?.scrollIntoView({ behavior: 'smooth', block: 'end' });
      }
  }, [Messages]);

    useEffect(() => {
      if (groupid) {
          api.callApi(
              instance,
              [process.env.REACT_APP_B2C_SCOPE ?? ''],
              `${process.env.REACT_APP_CLIENTEX_APIBASE}/chatgroups/${groupid}/chatmessages?Count=30&SortOrder=desc`,
              "GET",
              undefined
          ).then(async (result: Response) => {
              var response: any = await result.json();
              var chatMessages = response?.result;
              SetMessages([ ...chatMessages ]);
          }).catch((reason) => console.log("failed to load additional messages", reason))
      }
  }, [groupid])

    useEffect(() => {
      Connection?.on("reconnecting", () => setIsReconnecting(true));
      Connection?.on("reconnected", () => setIsReconnecting(false));
    }, [Connection, setIsReconnecting]);

    useEffect(() => {
      if (Group) {
         const name = Group.users.filter(usr => usr.id !== ChatUser?.id).map(x => x.name).join(" & ");
         setChatGroupName(name);
         setChatGroupUser(Group.users.find(u => u.id === ChatUser?.id));
         Connection?.on("statusChange", connectionStatusUpdateHandler);
      }
    }, [ChatUser, Group, groupid]);

    useEffect(() => {
      if((Messages?.length ?? 0) > 0){
         let map: {[key: string]: APIChatMessage[]} = {};
         Messages?.filter((x: APIChatMessage) => x?.group === groupid).forEach((message: APIChatMessage) => {
            let date = new Date(message.time).toLocaleDateString();
            if(!map[date]){
               map[date] = [];
            }
            map[date].push(message);
         });
         const orderedMap: {[key: string]: APIChatMessage[]} = {};
         Object.keys(map).sort((a, b) => new Date(a) < new Date(b) ? 1 : -1).forEach((item) => orderedMap[item] = map[item]);
         setMessageGroups(orderedMap);
      }
    }, [Messages]);

    const connectionStatusUpdateHandler = (msg: StatusMessage) => {
      if (Group) {
         const updatedUsers = Group.users.map((user: ChatGroupUser) => {
            if (user.id === msg.sender && msg.connectionStatus !== null) {
               user.connectionStatus = msg.connectionStatus
            }
            return user;
         })
         SetGroup({ ...Group, users: updatedUsers})
      }
    }

    const sendMessage = async () => {
      if(!Connection || Connection.state !== HubConnectionState.Connected) {
         return;
      }

      let msg: APIChatMessage = {
         group: groupid ?? "",
         message: messageInput,
         id: "",
         sender: ChatUser?.id ?? "",
         time: new Date().toUTCString(),
         broadcastId: null
      };
      Connection.invoke("sendChat", msg ).catch(err => console.log(err));
      setMessageInput('');
    }

    const joinChat = (wasAccepted: boolean) => {
      api.callApi(
         instance,
         [process.env.REACT_APP_B2C_SCOPE ?? ''],
         `${process.env.REACT_APP_CLIENTEX_APIBASE}/chatgroup/${Group?.id}/user`,
         'PATCH',
         JSON.stringify(
            {
               connectionStatus: wasAccepted ? ChatConnectionStatus.Accepted : ChatConnectionStatus.Denied
            }
         )
      ).then(async (result: Response) => {
         if(result.status >= 200 && result.status < 300) {
            let status = wasAccepted ? StatusCode.JoinedGroup : StatusCode.LeftGroup;
            let msg = `${ChatUser?.name} has ${wasAccepted ? "joined" : "declined to join"} the chat.`;
            Connection?.send("sendStatus", { code: status, group: groupid, sender: ChatUser?.id, message: msg, connectionStatus: wasAccepted ? ChatConnectionStatus.Accepted : ChatConnectionStatus.Denied })
            if(!wasAccepted) {
               navigate('/main/chat');
            }
         } else {
            toast.error("Failed to accept/decline the chat group. Please try again later.", ToastConfig);
         }
      });
    }

    const messageLoader = () => {
      if (groupid) {
         const earliestMessageTime = getEarliestMessage(Messages)?.time;
          api.callApi(
              instance,
              [process.env.REACT_APP_B2C_SCOPE ?? ''],
              `${process.env.REACT_APP_CLIENTEX_APIBASE}/chatgroups/${groupid}/chatmessages?Before=${earliestMessageTime}&Count=30&SortOrder=desc`,
              "GET",
              undefined
          ).then(async (result: Response) => {
              var response: any = await result.json();
              var chatMessages = response?.result;
              SetMessages([ ...Messages, ...chatMessages ]);
              setHasMoreMessages(response?.result?.length > MESSAGE_PAGING_LENGTH);
          }).catch((reason) => console.log("failed to load additional messages", reason))
      }
  }


    return (
      <div className="content-page-chat">
         <div className="container-fluid p-0">
            <div className=" chat-page bg-light">
               <div className="chat-data-block">
                  <div className="row m-0">
                     <div className="col-lg-12 chat-data p-0 chat-data-right">
                           <div className="chat-content animate__animated animate__fadeIn active" data-toggle-extra="tab-content" id="user-content-1">
                              <div className="chat-head d-flex justify-content-between align-items-center bg-white px-3 px-md-4 py-2">
                                 <div className="d-flex align-items-center">
                                    <div className="sidebar-toggle" onClick={e => userProfile?.SetChatNavOpen(true)}>
                                       <i className="ri-arrow-left-s-line"></i>
                                    </div>
                                    <div className="media  justify-content-between chat-user-box align-items-center">
                                       <div>
                                          <div className="chat-profile mr-2 mr-md-3">
                                             <img src={ClientTrackLogo} alt="chat-user" className="avatar-50 rounded" />
                                          </div>
                                       </div>
                                       <div className="media-body chat-text">
                                          <div className="d-flex align-items-center chat-right">
                                             <h5 className="chat-name">
                                                   {chatGroupName}
                                                </h5>
                                          </div>
                                          <p className="mb-0 text-primary">Online</p>
                                       </div>
                                    </div>
                                 </div>
                              </div>
                              <div className="chat-content scroller px-3 px-md-4" id="scrollableTarget" ref={chatbox} data-testid="chatbox"
                                 style={{
                                    display: 'flex',
                                    flexDirection: 'column-reverse',
                                    overflow: 'auto',
                                    transition: "filter 400ms",
                                    filter: `blur(${chatGroupUser?.connectionStatus === ChatConnectionStatus.Pending ? "3px" : "0"})`,
                                    WebkitFilter: `blur(${chatGroupUser?.connectionStatus === ChatConnectionStatus.Pending ? "3px" : "0"})`}}>
                                 { !!Messages && Messages?.length > 0 && !!messageGroups && chatGroupUser?.connectionStatus !== ChatConnectionStatus.Denied &&
                                    <InfiniteScroll
                                       dataLength={Messages?.length || 0}
                                       next={messageLoader}
                                       hasMore={hasMoreMessages}
                                       loader={<div className="text-center mt-5"><Loading /></div>}
                                       scrollableTarget="scrollableTarget"
                                       style={{ display: 'flex', flexDirection: 'column-reverse' }}
                                       inverse={true} //To put endMessage and loader to the top.
                                    >
                                       { 
                                          Object.keys(messageGroups ?? {}).map((groupKey, i) => 
                                          <div key={groupKey} className="chat-tab-box" data-testid="chat-group">
                                             <div className="chat-day-title">
                                                <div className="chat-day-date">
                                                   <div className="">
                                                      <span className="title">{groupKey}</span>
                                                   </div>
                                                </div>
                                             </div>
                                                {messageGroups && messageGroups[groupKey]?.sort((msg1: APIChatMessage, msg2: APIChatMessage) => new Date(msg1.time) > new Date(msg2.time) ? 1 : -1)
                                                .map((msg: APIChatMessage, index: number) => (
                                                   <ChatMessage
                                                      messageType={msg.sender === ChatUser?.id 
                                                         ? ChatMessageType.User 
                                                         : msg.broadcastId ? ChatMessageType.System : ChatMessageType.OtherUser}
                                                      messageContent={msg.message}
                                                      messageDate={msg.time}
                                                      key={msg.id}
                                                      isSending={msg.id === ""}
                                                      chatGroup={Group}
                                                      showSenderName={index === 0 || messageGroups[groupKey]?.at(index - 1)?.sender !== msg.sender}
                                                      sender={msg.sender} />
                                                ))}
                                          </div>
                                    )}   
                                    </InfiniteScroll>
                                 }
                              </div>
                              {
                                 chatGroupUser?.connectionStatus === ChatConnectionStatus.Pending &&
                                 <div className='position-absolute' style={{top: "40%", left: "35%"}}>
                                    <ChatGroupAcceptance
                                       chatUsers={Group?.users.filter(u => u.id !== ChatUser?.id).map(filtered => filtered.name) ?? []}
                                       onAccept={() => joinChat(true)}
                                       onDecline={() => joinChat(false)} />
                                 </div>
                              }
                              {
                                 isReconnecting &&
                                 <div className='position-absolute' style={{top: "40%", left: "35%"}}>
                                    <div className='card mx-auto'>
                                       <div className='card-body'>
                                          <p className='text-danger'>Connection has been lost. Reconnecting.</p>
                                       </div>
                                    </div>
                                 </div>
                              }
                              {
                                 chatGroupUser?.connectionStatus === ChatConnectionStatus.Denied &&
                                 <div className='position-absolute' style={{top: "40%", left: "35%"}}>
                                    <div className='card mx-auto'>
                                       <div className='card-body'>
                                          <p className='text-danger'>You have declined to participate in this chat</p>
                                       </div>
                                    </div>
                                 </div>
                              }
                           </div>
                        <div className="chat-footer bg-white px-3 px-md-4">
                           <form className="d-flex align-items-center border-top pt-3" id="chat-message" action={"javascript:void(0);"}>
                                 <input type="text" name="message" autoComplete="off" className="form-control mx-2 mx-md-3 bg-light border-none" placeholder="Write your message" onChange={e => setMessageInput(e.target.value)} value={messageInput}/>
                                 <div className="d-flex align-items-center">
                                    {/* <!-- <i className="las la-microphone bg-light font-size-20 iq-card-icon-small mr-2 mr-md-3"></i> --> */}
                                    <button className="bg-primary btn p-0 btn-sm font-size-20 iq-card-icon-small mr-2 mr-md-3" type="submit" onClick={e => sendMessage()} >
                                       <i className="lab la-telegram-plane"></i>
                                    </button>
                                 </div>
                           </form>
                        </div>            
                     </div>
                  </div>
               </div>
            </div>
         </div>
      </div>
    )
}

export default ChatWindow;